import { AxiosError } from 'axios';

import { AxiosOptions } from '../../@types/models';

export const axiosOptions = (aO: AxiosOptions = {}) => ({ data: { axiosOptions: aO } });

export const axiosErrorHandler = ({
  error,
  fallback = 'Server error, something went wrong on our end.',
  code = '',
}: {
  error: AxiosError;
  fallback?: string;
  code?: string;
}): string => {
  if (code) {
    // Return a unique error code if we need to be more opaque to the user
    return `Error Code: ${code}`;
  } else if (error.response?.data) {
    // BE plans to use JSON-API spec for error format in the future so lets try that first
    // https://jsonapi.org/format/#error-objects
    return error.response.data.title || (typeof error.response.data === 'string' && error.response.data) || fallback;
  } else if (error.request) {
    return error.request;
  } else if (error.message) {
    return error.message;
  } else {
    return fallback;
  }
};
