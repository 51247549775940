import { ErrorResponse } from '@apollo/client/link/error';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button, Popover, PopoverContent, PopoverTrigger } from 'packages/elements';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import logoImg from '@/assets/logo.svg';
import { AvatarPlaceholder } from '@/components/AvatarPlaceholder';
import { CreateOrgForm, CreateOrgValues } from '@/components/CreateOrgForm';
import { LoadingSpinner, LoadingSpinnerWrapper } from '@/components/LoadingSpinner';
import { trialLimitText } from '@/constants/copy';
import { accountsLogout } from '@/constants/links';
import { Anchor } from '@/elements/Anchor';
import { InlineIcon } from '@/elements/Icon';
import { Modal } from '@/elements/Modal';
import { Toast } from '@/elements/Toast';
import {
  GetAllAccountsDocument,
  useCreateOrganizationMutation,
  useGetAllAccountsQuery,
} from '@/generated/upbound-graphql';
import { hasLimitReachedError } from '@/graphql';
import { useCurrentUser } from '@/graphql/reactiveVars';
import { app, external } from '@/routes';

import { NextPageWithConfig } from './_app.page';

const CreateOrgModal: React.FC<{ isOpen: boolean; onClose: (account?: string) => void; subHeader?: string }> = ({
  isOpen,
  onClose,
  subHeader = 'Get started by specifying a unique ID and display name for your new organization.',
}) => {
  const [createOrg] = useCreateOrganizationMutation({ refetchQueries: [{ query: GetAllAccountsDocument }] });

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <h4 className="text-xl text-neutral-700 mb-5">Create Organization</h4>
      <p className="font-normal text-sm text-neutral-700 mb-8">{subHeader}</p>
      <CreateOrgForm
        onCancel={() => onClose()}
        onSubmit={async (values: CreateOrgValues, { setSubmitting }) => {
          setSubmitting(true);

          try {
            await createOrg({ variables: { payload: values } });

            onClose(values.name);
          } catch (err) {
            let errMsg;
            const graphQLErrors = (err as ErrorResponse | undefined)?.graphQLErrors;
            if (graphQLErrors && hasLimitReachedError(graphQLErrors)) {
              errMsg = trialLimitText;
            } else {
              errMsg = `Resource failed to create, please try again: ${(err as { message?: string })?.message}`;
            }

            Toast({ msg: errMsg });
            setSubmitting(false);
          }
        }}
      />
    </Modal>
  );
};

const popoverRowStyle = twMerge(
  'flex items-center py-1.5 px-4',
  'cursor-pointer text-sm list-none transition-colors ease-in-out text-neutral-700 hover:bg-purple-shades-25 hover:bg-opacity-50 font-normal',
);

const SelectOrg: NextPageWithConfig = () => {
  const { data: accountsData } = useGetAllAccountsQuery();
  const currentUser = useCurrentUser();
  const router = useRouter();
  const [showPopover, setShowPopover] = useState(false);
  const [showCreateOrg, setShowCreateOrg] = useState(false);

  if (!accountsData) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner visible={true} size="xl" />
      </LoadingSpinnerWrapper>
    );
  }

  const orgs = accountsData.accounts.filter(
    (a): a is Extract<typeof a, { __typename?: 'OrgAccount' | undefined }> => a.__typename === 'OrgAccount',
  );

  const onClose = (account?: string) => {
    setShowCreateOrg(false);

    if (account) {
      onSelect(account)();
    }
  };

  const onSelect = (account: string) => () => {
    const path = router.asPath;
    const searchParams = path.includes('?') ? `?${new URLSearchParams(path.split('?')[1])}` : '';

    router.push(app.org.url(account) + searchParams);
  };

  return (
    <div className="min-h-full flex flex-col bg-neutral-shades-25">
      <div className="flex justify-end py-8 px-12 grow-0">
        <Popover open={showPopover} onOpenChange={setShowPopover}>
          <PopoverTrigger className="flex items-center text-base p-1">
            {currentUser.firstName}
            {` `}
            {currentUser.lastName}
            <InlineIcon name="downChevron" color="fillBlackBlack" width={16} height={16} ml="10px" />
          </PopoverTrigger>
          <PopoverContent
            align="end"
            className="bg-neutral-0 py-3 px-1 mr-1 border border-solid border-neutral-shades-150 w-auto"
          >
            <ul>
              <li
                className={popoverRowStyle}
                onClick={e => {
                  e.stopPropagation();
                  setShowPopover(false);
                  window.location.assign(external.accountsSettings.url(undefined, window.location.href));
                }}
              >
                <InlineIcon name="settings" color="fillBlackBlack" width={16} height={16} mr="10px" />
                Account Settings
              </li>
              <Anchor href={accountsLogout.url(window.location.origin)}>
                <li
                  className={popoverRowStyle}
                  onClick={e => {
                    e.stopPropagation();
                    setShowPopover(false);
                  }}
                >
                  <InlineIcon name="signOut" color="fillBlackBlack" width={16} height={16} mr="10px" />
                  Sign out
                </li>
              </Anchor>
            </ul>
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex justify-center items-center grow">
        <div className="flex flex-col items-center w-full max-w-[600px]">
          <Image src={logoImg.src} alt="Upbound Logo" width="171" height="42" />
          <h3 className="text-2xl mt-10 text-teal-800">👋 Hello, {currentUser.email}!</h3>
          <p className="font-normal text-sm text-neutral-700 mt-4">
            {orgs.length === 0
              ? 'To continue you must create an organization.'
              : 'Select an organization or create a new one.'}
          </p>
          <div className="flex flex-col w-full mt-8 mb-4">
            {orgs.map(o => (
              <div
                className="flex items-center p-5 border border-solid border-neutral-shades-150 rounded-md bg-neutral-0 mb-4 cursor-pointer"
                key={o.id}
                onClick={onSelect(o.id)}
              >
                <AvatarPlaceholder type="OrgAccount" organization={o.organization} size={32} />
                <div className="flex">
                  <p className="text-teal-800 font-bold ml-3">{o.organization.displayName}</p>
                </div>
                <div className="flex grow justify-end">
                  <Button btnType="Secondary">Select</Button>
                </div>
              </div>
            ))}
          </div>
          <Button onClick={() => setShowCreateOrg(true)}>Create Organization</Button>
        </div>
        <CreateOrgModal
          isOpen={showCreateOrg}
          onClose={onClose}
          subHeader="An organization is required to use Upbound and manage Marketplace listings."
        />
      </div>
    </div>
  );
};

SelectOrg.config = { reRoute: 'selectOrg', layout: false };

export default SelectOrg;
