import { AxiosError } from 'axios';

import { AxiosOptions } from '@/@types/models';
import { ControlPlaneConfigurationProvider } from '@/generated/upbound-graphql';
import { ApiGitSource } from '@/utils/github';

import { api as sharedApi } from '../shared';
import { axiosOptions } from './upboundApi.helpers';

export interface ApiError {
  message: string;
  status?: number;
}

const API_VERSION = 'v1';
const api = () => sharedApi();

// TODO: Let's use zod for validating the fetch results and generate types
// https://github.com/upbound/managed-control-planes/blob/main/internal/api/gitsource_api.yaml#L46
export function fetchGitSources(
  provider: ControlPlaneConfigurationProvider = ControlPlaneConfigurationProvider.Github,
  aO?: AxiosOptions,
) {
  return api()
    .get<{ availableContexts: ApiGitSource[] }>(`${API_VERSION}/gitSources/${provider.toLowerCase()}`, axiosOptions(aO))
    .then(resp => resp.data);
}

// https://github.com/upbound/managed-control-planes/blob/main/internal/api/gitsource_api.yaml#L59
// returns true if the repoName is not existing as a part of a provided account
export async function checkRepoAvailability(context: string, repoName: string, aO?: AxiosOptions): Promise<boolean> {
  const provider = ControlPlaneConfigurationProvider.Github;
  const notExistingErrorCode = 404;

  // eslint-disable-next-line max-len
  const url = `/${API_VERSION}/gitSources/${encodeURIComponent(provider.toLowerCase())}/repos/${encodeURIComponent(context)}/${encodeURIComponent(repoName)}`;

  const status = await api()
    .head(url, axiosOptions(aO))
    .then(resp => resp.status)
    .catch((e: any | AxiosError) => e?.response?.status);

  return status === notExistingErrorCode;
}

export async function accountAvailable(name: string, aO?: AxiosOptions): Promise<boolean> {
  const resp = await api().get<{ available: boolean }>(
    `/${API_VERSION}/validate/accountAvailable/${name}`,
    axiosOptions(aO),
  );
  return !!resp.data.available;
}
