import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { cpNameCharError } from '@/constants/copy';

export function DisableFormikButton<V = Array<any>>(
  { isSubmitting, isValid, dirty }: FormikProps<V>,
  checkDirty = true,
  isLoading?: boolean,
) {
  if (isLoading === undefined) {
    if (checkDirty === false) {
      return isSubmitting || !isValid;
    }

    return isSubmitting || !isValid || !dirty;
  }

  if (checkDirty === false) {
    return isLoading || isSubmitting || !isValid;
  }

  return isLoading || isSubmitting || !isValid || !dirty;
}

export const cpNameValidation = Yup.string()
  .required('Required')
  .min(2, 'Control plane name must be at least two characters')
  .max(255, 'Control plane name cannot be longer than 255 characters')
  .test('Spaces Caps & Dashes Validation', cpNameCharError, function (value: string | undefined) {
    const regexArr = [/^\S*$/, /^[a-z][a-z0-9-]*[a-z0-9]$/, /^(?!.*--)/];
    return value ? regexArr.every((regex: RegExp) => regex.test(value)) : false;
  });
