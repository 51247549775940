import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Button } from '@/elements/Button';
import { Box, Flex } from '@/elements/Div';
import { Input } from '@/elements/forms/Input';
import { ValidationError } from '@/elements/forms/ValidationError';
import { Paragraph } from '@/elements/Paragraph';
import { DisableFormikButton } from '@/utils/forms';

import { UsernameInput } from './UserNameInput';

export interface CreateOrgValues {
  name: string;
  displayName: string;
}

const pattern = /^(?=.{2,100}$)[a-z0-9]+(-[a-z0-9]+)*$/;

const CreateOrgSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .matches(
      pattern,
      'Must be at least 2 characters long. Lowercase letters only. No starting, ending, or repeat dashes.',
    ),
  displayName: Yup.string().required('Required'),
});

export const CreateOrgForm: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: CreateOrgValues, formikHelpers: FormikHelpers<CreateOrgValues>) => void | Promise<any>;
  onCancel?: () => void;
}> = ({ onSubmit, onCancel }) => {
  const [nameAvailable, setNameAvailable] = useState(false);
  const [checkingName, setCheckingName] = useState(false);
  const [checkedName, setCheckedName] = useState(false);

  return (
    <Formik
      initialValues={{ name: '', displayName: '' }}
      onSubmit={onSubmit}
      validationSchema={CreateOrgSchema}
      validateOnBlur={false}
    >
      {formik => (
        <Form>
          <Box minHeight="90px">
            <Field
              name="name"
              component={UsernameInput}
              setNameAvailable={setNameAvailable}
              setCheckingName={setCheckingName}
              setCheckedName={setCheckedName}
              setNameError={null}
              label="Organization ID"
              placeholder="e.g. acmecorporation"
              errors={formik.errors.name}
              required={true}
              autoFocus={true}
            />
            <Box mt="6px">
              {!!formik.errors.name && <ValidationError msg={formik.errors.name} />}
              {!!checkedName && !nameAvailable && !formik.errors.name && (
                <Paragraph size="small" color="lightRed">
                  This Organization ID has already been taken. Please try again.
                </Paragraph>
              )}
              {!!checkedName && !!nameAvailable && !formik.errors.name && (
                <Paragraph color="blueGrey" size="small" data-testid="unique-url">
                  This ID is used in URLs. It must be unique and cannot be changed.
                </Paragraph>
              )}
            </Box>
          </Box>
          <Box mt={20}>
            <Field
              name="displayName"
              component={Input}
              label="Organization Display Name"
              placeholder="e.g. Acme Corporation"
              required={true}
              errors={!!formik.touched.displayName && formik.errors.displayName}
            />
            <Paragraph color="blueGrey" size="small" mt="6px">
              We&apos;ll use this name to describe your new organization to humans.
            </Paragraph>
          </Box>
          <Flex mt={30}>
            {!!onCancel && (
              <Button onClick={onCancel} btnType="paleGreyOutline" type="button" mr={10}>
                Cancel
              </Button>
            )}
            <Button
              btnType="purpleFill"
              loadingInline={checkingName}
              disabled={!nameAvailable || DisableFormikButton(formik, true, checkingName)}
              type="submit"
            >
              Create Organization
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
