import React from 'react';

import { COLORS } from '@/constants/styles';
import { BaseProps, Box } from '@/elements/Div';

import { Paragraph } from '../../Paragraph';

interface Props extends BaseProps {
  color?: COLORS;
  msg?: string;
}

export const ValidationError: React.FC<Omit<Props, 'css'>> = ({ color, msg, ...props }) => {
  if (msg === 'Required') {
    return null;
  }

  return (
    <Box {...props}>
      <Paragraph size="small" color={color ? color : 'lightRed'} whiteSpace="pre-line">
        {msg}
      </Paragraph>
    </Box>
  );
};
